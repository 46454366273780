body {
  padding-top: 0 !important;
  background-color: transparent !important;
}
:root {
  --primary-linear: linear-gradient(
    90deg,
    rgba(30, 111, 195, 1) 0%,
    rgba(45, 156, 244, 1) 100%
  );
  --primary-color: rgba(30, 111, 195);
  --secondary-color: rgba(45, 156, 244, 1);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@page {
  size: auto !important;
}

.shadowed {
  transition: all 0.1s ease-in-out;
}
.shadowed:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.btn-dollar {
  background: var(--primary-linear);
  color: white !important;
}

.btn-iqd {
  background: var(--primary-linear);
  color: white !important;
}
.bg-newCard {
  color: #fff;
  background: var(--primary-linear) !important;
}

.newSafeBox,
.newSellReturnBox-dashboard,
.card-show {
  color: white;
  background: var(--primary-linear);
}

.last-login-dashboard {
  color: white !important;
  background: var(--primary-linear);
}
.bar-dashbord {
  color: white !important;
  background: var(--primary-color);
}
.last-login-dashboard:checked {
  background-color: red;
}
.blured {
  /* filter: blur(4px); */
  backdrop-filter: blur(15px);
  backdrop-filter: brightness(120%);
}
.hoverd:hover {
  backdrop-filter: brightness(150%);
}
.selected {
  backdrop-filter: brightness(80%);
}
/* .Bar-dashboard:hover {
  backdrop-filter: brightness(120%);
} */
.searchInput-appBar {
  color: white !important;
  padding-inline-start: 0.75rem;
}
.searchInput-appBar::placeholder {
  color: white !important;
}

.NavBar {
  background-color: var(--primary-color) !important;
}
.hr {
  border: 1px solid;
  border-radius: 5px;
}

table {
  width: 100%;
  /* background-color: #fff; */
  font-family: Nunito, Speda, NRT-Reg;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column100 {
  width: 130px;
  padding-left: 25px;
}

.column100.column8 {
  width: 265px;
  padding-left: 42px;
}

.row100.head th {
  padding-top: 24px;
  padding-bottom: 20px;
}

.row100 td {
  padding-top: 18px;
  padding-bottom: 14px;
}

.table100.ver2 th {
  font-size: 12px;
  color: #fff;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: #333;
}

.table100.ver2 .row100:hover td {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-ver2 {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-head-ver2 {
  background-color: #484848 !important;
}

.table100.ver2 .row100 td:hover {
  background-color: #57b846;
  color: #fff;
}

/* .table100.ver2 tbody tr:nth-child(even) {
  background-color: #eaf8e6;
} */

.table100.ver2 td {
  font-size: 14px;

  line-height: 1.4;
}

.table100.ver2 th {
  font-size: 12px;
  color: #fff;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: #333;
}

.table100.ver2 .row100:hover td {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-ver2 {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-head-ver2 {
  background-color: #484848 !important;
}

.table100.ver2 .row100 td:hover {
  background-color: #57b846;
  color: #fff;
}

.table100.ver2 .row100:hover td {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-ver2 {
  background-color: #83d160;
  color: #fff;
}

.table100.ver2 .hov-column-head-ver2 {
  background-color: #484848 !important;
}

.table100.ver2 .row100 td:hover {
  background-color: #57b846;
  color: #fff;
}

.glowBackground {
  box-shadow: 0 0 50px #fff, 0 0px 11px #1565c0 !important;
  background: var(--primary-linear);
  color: white !important;
}
.whiteButton {
  background-color: white !important;
  color: #1565c0 !important;
}
.buttonHoverd {
  transition: ease-in-out 0.2s !important;
}
.buttonHoverd:hover {
  transform: scale(1.08);
}

.sideBarBg {
  background: rgb(31, 137, 235) !important;
}
.sideBarList {
  background: rgb(30, 111, 195) !important;
  color: white !important;
  text-align: start !important;
}
.sideBarListItem {
  color: white !important;
  text-align: start !important;
}
.border-bottom {
  border-bottom: 5px solid rgb(0, 0, 0) !important;
}

.IconMenu {
  color: inherit;
  margin-right: 1px;
}
/* ?Sherko voucher css */

:root {
  --color-primary: #3b71ca;
  --color-secondary: #9fa6b2;
  --color-success: #14a44d;
  --color-danger: #20;
  --color-warning: #e4a11b;
  --color-info: #54b4d3;
  --color-light: #fbfbfb;
  --color-dark: #332d2d;
}
.hr-voucher {
  border: 1px solid var(--color-success) !important;
}
.text-color {
  color: var(--color-success);
}
.bg-color {
  background-color: var(--color-success);
}
.logo {
  width: 100px;
}
.border-down {
  border-bottom: 2px dotted var(--color-success);
}
.rounded {
  border-radius: 10px;
}
.note-voucher {
  text-align: justify;
  text-justify: inter-word;
}
.colorPicker {
  width: 40px;
  height: 40px;
  color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7px;
  padding: 5px;
  transition: all 0.2s ease-in-out;
}
.colorPicker:hover {
  transform: scale(1.15);
}
.bordered-color {
  /* filter: brightness(85%); */
  border: 1px solid black;
}
/* ?End Sherko voucher css */
.input-voucher {
  padding: 0;
  width: 150%;
  height: 150%;
  margin: -25%;
}

.cp_wrapper {
  overflow: hidden;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin: 0 20px;
  box-shadow: 1px 1px 3px 0px grey;
}

.table-sell-purchase {
  border-collapse: collapse !important;
  table-layout: auto !important;
}
.table-sell-purchase,
.table-sell-purchase th,
.table-sell-purchase .mixedTd,
.table-sell-purchase td {
  border: 1px solid rgb(92, 92, 92) !important;
}
.representataiveCustomerSellIncoicesTableReport {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}

.representataiveCustomerSellIncoicesTableReport,
.representataiveCustomerSellIncoicesTableReport th,
.representataiveCustomerSellIncoicesTableReport .mixedTd,
.representataiveCustomerSellIncoicesTableReport td {
  border: 1px solid rgb(92, 92, 92);
  padding: 0.4rem;
}
.representataiveCustomerSellIncoicesTableReport td {
  text-align: center;
  vertical-align: middle !important;
}

.table-yellow {
  background: rgb(255, 244, 195);
}
.table-blue-sky {
  background: rgb(175, 220, 255);
}
.table-gray {
  background: #d6d8db;
}
.table-red {
  background: #ebb59c;
}
.table-green {
  background: #dfeb9c;
}
.imageButton {
  position: relative;
  overflow: hidden;
}
.imageButton input[type="file"] {
  position: absolute;
  cursor: pointer;
  transform: scale(10);
  left: -50%;
  top: 0;
  opacity: 0;
}
.customerVisit_image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.bgDanger {
  background: red !important;
}
.textarea {
  overflow: hidden;
  resize: none;
}
.bg-red {
  background-color: #fce8e9;
}
.bg-green {
  background-color: #bfeaa3 !important;
}
.gap {
  gap: 10px !important;
}
.cursor-normal {
  cursor: auto !important;
}
.fs-sm {
  font-size: small !important;
}
.grow-1 {
  flex-basis: 50%;
  flex-grow: 1;
}
.table-sell-invoice {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}
.table-sell-invoice,
.table-sell-invoice th,
.table-sell-invoice td {
  padding: 0.5rem;
}
/* App Bar */
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.Action-container {
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  width: max-content;
  height: fit-content;
  background-color: var(--primary-color);
  padding: 1rem 2rem;
  margin: 0 auto;
  border-radius: 0.25rem;
  font-size: 75%;
}
.Action-menu {
  color: white !important;
  font-size: 22px;
}
.gap-1 {
  gap: 0.5rem !important;
}
.gap-2 {
  gap: 1rem !important;
}
.gap-3 {
  gap: 2rem !important;
}
.gap-4 {
  gap: 3rem !important;
}
.gap-5 {
  gap: 5rem !important;
}
.h-max {
  height: max-content;
}
p {
  padding: 0 !important;
  margin: 0 !important;
}
.grid-attachment-customer-show {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
}

@media (max-width: 1024px) {
  .grid-attachment-customer-show {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .grid-attachment-customer-show {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.text-primary {
  color: var(--primary-color) !important;
}
.text-light {
  color: var(--color-light);
}
.bg-main {
  background-color: var(--primary-color) !important;
}
.bg-light {
  background-color: var(--color-light);
}

.flex {
  display: flex !important;
}
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-2 {
  flex: 2 !important;
}

.justify-center {
  justify-content: center !important;
}
.itmes-center {
  align-items: center !important;
}

/* ! Font Size  */
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-5xl {
  font-size: 3rem;
}
.text-6xl {
  font-size: 3.75rem;
}
.text-7xl {
  font-size: 4.5rem;
}
.text-8xl {
  font-size: 6rem;
}
.text-9xl {
  font-size: 8rem;
}

.min-width-0 {
  min-width: 0 !important;
}
.text-warp {
  text-wrap: wrap !important;
}
.text-start {
  text-align: start;
}
.text-nowarp {
  white-space: nowrap;
}
.B-O-P {
  border: 1px solid var(--primary-color) !important;
  border-radius: 0.25rem;
  background-color: transparent;
  padding: 0.35rem 0.75rem !important;
  height: 1.75rem;
  color: var(--primary-color) !important;
  transition: all ease-in-out 200ms;
}
.B-O-P:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.AB-O-P {
  border: 1px solid var(--primary-color) !important;
  border-radius: 0.25rem;
  background-color: var(--primary-color);
  padding: 0.35rem 0.75rem !important;
  height: 1.75rem;
  color: white !important;
  transition: all ease-in-out 200ms;
}
.AB-O-P:hover {
  box-shadow: 0px 3px 5px -2px rgba(30, 111, 195, 0.8);
}
.pointer {
  cursor: pointer !important;
}
.object-contain {
  object-fit: contain !important;
}
/* ? Blance Sheet */
.card-main-container {
  transition: all 150ms linear !important;
}
.card-main-container:hover {
  transform: scale(1.01);
}
.border-main {
  border: 2px solid var(--primary-color);
}
.border-green {
  border: 2px solid #0c813b;
}
.border-red {
  border: 2px solid #992412;
}
.border-orange {
  border: 2px solid #d88c0a;
}
.card-main-header {
  background: var(--primary-color);
  color: white;
}
.card-main-header-green {
  background: #0c813b;
  color: white;
}
.card-main-header-red {
  background: #992412;
  color: white;
}
.card-main-header-orange {
  background: #d88c0a;
  color: white;
}
.bg-gray-100 {
  background-color: hsla(0, 0%, 30%, 0.045);
}
.bg-gray-200 {
  background-color: hsla(0, 0%, 30%, 0.09);
}
.LatestPriceModal {
  height: 600px;
  overflow-y: scroll;
}

/* ! TEST */
.custom-header {
  display: flex;
  align-items: center;
}

.my-button {
  margin-left: 10px;
}
